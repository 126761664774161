.hero {
  grid-column: 1/13;
  text-align: center;
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  @include tablet {
    grid-column: 2/12;
  }

  @include desktop {
    grid-column: 3/11;
  }

  .hero__title {
    // if any
  }

  .hero__ingress {
    font-size: 24px;
    font-family: Georgia, sans-serif;
  }
}
