.image-aspect-ratio--16-9 {
  width: 100%;
  aspect-ratio: 16/9;
  background: $green;
}

@supports not (aspect-ratio: auto) {
  .image-aspect-ratio--16-9 {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
  }
}

.image-fit--cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.image-fit--contain {
  object-fit: contain;
  height: fit-content;
  vertical-align: middle;
}
