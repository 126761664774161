.image-box {
  @extend .grid--no-gap;
  background: $green;
  position: relative;

  .image-box__only-image {
    grid-column: span 12;
    height: 400px;
  }

  .image-box__only-text {
    grid-column: span 12;
    align-self: center;
    justify-self: center;
    text-align: center;
    padding: 24px;

    .image-box__content__link {
      text-decoration: none;

      .image-box__content__arrow {
        transition: 0.3s;
      }

      &:hover {
        .image-box__content__arrow {
          margin-left: 8px;
        }
      }
    }

    .image-box__content__title {
      color: $pink;
      font-size: 24px;
    }

    .image-box__content__text {
      * {
        color: white;
        margin: 0;
        font-size: 16px;
      }
    }
  }

  .image-box__image {
    grid-column: span 12;
    height: 400px;

    @include tabletanddesktop {
      grid-column: span 6;
    }
  }

  .image-box__content {
    grid-column: span 12;
    align-self: center;
    justify-self: center;
    padding: 24px;

    @include tabletanddesktop {
      grid-column: span 6;
    }

    .image-box__content__link {
      text-decoration: none;

      .image-box__content__arrow {
        transition: 0.3s;
      }

      &:hover {
        .image-box__content__arrow {
          margin-left: 8px;
        }
      }
    }

    .image-box__content__title {
      color: $pink;
      font-size: 24px;
    }

    .image-box__content__text {
      * {
        color: white;
        margin: 0;
        font-size: 16px;
      }
    }
  }
}
