@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600&display=swap");
@import "./breakpoints";
@import "./colors";
@import "./grid";
@import "./images";
@import "./header";
@import "./footer";
@import "./typography";
@import "./spacing";
@import "./buttons";
@import "./tables";
@import "./forms";
@import "./titleAndIngress";
@import "./hero";
@import "./slideshow";
@import "./breadcrumbs";
@import "./articleCard";
@import "./imageBlockBox";
@import "./faqBox";
// pages
@import "./pages/mainStartPage";
@import "./pages/startPage";
@import "./pages/articlePage";
@import "./pages/searchPage";
@import "./pages/locationListPage";
@import "./pages/locationPage";
@import "./pages/ceremonyListPage";
// js styling
@import "./js/graveAuthority";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: "Nunito", sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
  padding-bottom: 24px;
  background: $beige;

  @include tablet {
    padding-bottom: 40px;
  }

  @include desktop {
    padding-bottom: 56px;
  }
}

.keep-in-main {
  width: 1280px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.full-width--center {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hr {
  flex: 1;
  margin: 0;
  border: none;
  height: 2px;
  background: $grey;
}

.link {
  color: $light-green;
  text-decoration: underline;
  font-size: 16px;

  @include tabletanddesktop {
    font-size: 18px;
  }

  &:hover {
    color: $green;
  }
}

.link-white {
  color: #ffffff;
  text-decoration: underline;
  font-size: 16px;

  @include tabletanddesktop {
    font-size: 18px;
  }

  &:hover {
    color: $green;
  }
}
