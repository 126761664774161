.articlePage-image {
  grid-column: 1/13;
}

.articlePage-body {
  grid-column: 1/13;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  * {
    margin: 0;
  }

  img {
    max-width: 100%;
    @extend .image-fit--contain;
  }

  @include tablet {
    grid-column: 2/12;
  }

  @include desktop {
    grid-column: 3/11;
  }
}
