h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Georgia, sans-serif;
  font-weight: 400;
  color: $green;
  margin: 0;
}

h1 {
  font-size: 32px;

  @include tablet {
    font-size: 40px;
  }

  @include desktop {
    font-size: 48px;
  }
}

h2 {
  font-size: 24px;

  @include tablet {
    font-size: 24px;
  }

  @include desktop {
    font-size: 32px;
  }
}

h3 {
  font-size: 20px;

  @include tablet {
    font-size: 20px;
  }

  @include desktop {
    font-size: 24px;
  }
}

h4,
h5,
h6 {
  font-size: 24px;

  @include tablet {
    font-size: 24px;
  }

  @include desktop {
    font-size: 32px;
  }
}

p,
span,
li {
  color: $black;
  font-size: 16px;
  line-height: 150%;

  @include tabletanddesktop {
    font-size: 18px;
  }
}

.ingress {
  font-size: 24px;
  font-family: Georgia, sans-serif;
}
