.title-and-ingress {
  @extend .grid;

  .title-and-ingress__title {
    grid-column: 1/13;
    text-align: center;

    @include tablet {
      grid-column: 2/12;
    }

    @include desktop {
      grid-column: 3/11;
    }
  }

  .title-and-ingress__ingress {
    grid-column: 1/13;
    text-align: center;

    @include tablet {
      grid-column: 2/12;
    }

    @include desktop {
      grid-column: 3/11;
    }
  }
}
