.locationPage-list-element {
  grid-column: span 12;
  background: $beige2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  cursor: pointer;
  text-decoration: none;
  font-size: 24px;
  font-family: Georgia, sans-serif;
  color: $green;

  @include tablet {
    grid-column: span 6;
  }

  @include desktop {
    grid-column: span 4;
  }

  &:hover,
  &:focus {
    background: $beige3;
  }
}
