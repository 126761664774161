@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600&display=swap");
.form {
  @extend .grid;

  .form__streetName {
    grid-column: 1/13;
    display: flex;
    flex-direction: column;

    @include tablet {
      grid-column: 5/9;
    }

    @include desktop {
      grid-column: 5/9;
    }
  }

  .form__label {
    font-size: 16px;
  }

  .form__input {
    padding: 8px 16px;
    border-radius: 4px;
    margin-top: 4px;
    border: none;
    font-size: 16px;
  }

  .form__submit {
    grid-column: 1/13;
    display: flex;
    justify-content: center;
  }
}
