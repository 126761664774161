/* main menu */
.startpage-buttons
{
    display: flex;

    @include tabletanddesktop
    {
        justify-content: center;
    }

    @include smartphone
    {
        flex-direction: column;
        align-items: center;
    }

    a
    {
        &:first-child
        {
            @include smartphone
            {
                margin-bottom: 16px;
            }

            @include tabletanddesktop
            {
                margin-right: 32px;
            }
        }

        &:nth-child(2)
        {
            @include smartphone
            {
                margin-bottom: 16px;
            }

            @include tabletanddesktop
            {
                margin-right: 32px;
            }
        }
    }
}

.startpage-menu
{
    @extend .keep-in-main;
    @extend .grid;
    padding: 56px 16px !important;
    row-gap: 40px;

    .startpage-menu__element--one
    {
        grid-column: 1/13;
        display: flex;
        column-gap: 8px;

        @include tablet
        {
            grid-column: 1/7;
        }

        @include desktop
        {
            grid-column: 1/5;
        }
    }

    .startpage-menu__element--two
    {
        grid-column: 1/13;
        display: flex;
        column-gap: 8px;

        @include tablet
        {
            grid-column: 7/13;
        }

        @include desktop
        {
            grid-column: 5/9;
        }
    }

    .startpage-menu__element--three
    {
        grid-column: 1/13;
        display: flex;
        column-gap: 8px;

        @include tablet
        {
            grid-column: 1/7;
        }

        @include desktop
        {
            grid-column: 9/13;
        }
    }

    .startpage-menu__element--four
    {
        grid-column: 1/13;
        display: flex;
        column-gap: 8px;

        @include tablet
        {
            grid-column: 7/13;
        }

        @include desktop
        {
            grid-column: 1/5;
        }
    }

    .startpage-menu__element--five
    {
        grid-column: 1/13;
        display: flex;
        column-gap: 8px;

        @include tablet
        {
            grid-column: 1/7;
        }

        @include desktop
        {
            grid-column: 5/9;
        }
    }

    .startpage-menu__element--six
    {
        grid-column: 1/13;
        display: flex;
        column-gap: 8px;

        @include tablet
        {
            grid-column: 7/13;
        }

        @include desktop
        {
            grid-column: 9/13;
        }
    }

    .startpage-menu__element__icon
    {
        width: 40px;

        svg
        {
            width: 40px;

            *
            {
                stroke: $green;
            }
        }
    }

    .startpage-menu__element__link
    {
        display: block;
        font-family: Georgia, sans-serif;
        font-size: 24px;
        color: $green;
        margin-bottom: 8px;
        text-decoration: underline;
    }

    .startpage-menu__element__title
    {
        display: block;
        font-family: Georgia, sans-serif;
        font-size: 24px;
        color: $green;
        margin-bottom: 8px;
        line-height: 100%;
    }

    .startpage-menu__element__buttons
    {
        margin: 0;
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }
}
/* contact */

.startpage-contact
{
    @extend .grid;
    background: $green;
    height: 400px;
    color: white;

    .startpage-contact__contact
    {
        grid-column: span 12;
        padding: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 24px;

        @include desktop
        {
            grid-column: span 6;
        }

        .info
        {
            @extend .ingress;
            text-align: center;
            max-width: 600px;
        }
    }

    .startpage-contact__image
    {
        display: none;

        @include desktop
        {
            display: block;
            grid-column: span 6;
            overflow: hidden;
        }
    }
}

.article-carousel
{
    position: relative;
    overflow: hidden;
}


/* The track holds all the slides side-by-side */
.carousel-track
{
    display: flex;
    transition: transform 0.5s ease-in-out;
}

/* Each slide defaults to full width (for mobile) */
.carousel-slide
{
    min-width: 100%;
    box-sizing: border-box;
    padding: 0 5px; /* Optional: add some spacing between slides */
}

/* For tablets: 2 slides per view */
@media (min-width: 768px)
{
    .carousel-slide
    {
        min-width: 50%;
    }
}

/* For desktop: 3 slides per view */
@media (min-width: 1024px)
{
    .carousel-slide
    {
        min-width: 33.33%;
    }
}

/* Style for navigation buttons */
.carousel-btn
{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0,0,0,0.5);
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
    font-size: 1.5rem;
}

/* Position buttons on either side */
.carousel-btn.left
{
    left: 10px;
}

.carousel-btn.right
{
    right: 10px;
}