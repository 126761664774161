/* Slideshow container */
.slideshow {
  @extend .keep-in-main;
  padding: 0 !important;
  position: relative;
  margin: auto;

  .slideshow__mySlides {
    display: none;
  }

  /* Next & previous buttons */
  .slideshow__prev,
  .slideshow__next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  /* Position the "next button" to the right */
  .slideshow__next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  /* Fading animation */
  .slideshow__fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
  }

  @-webkit-keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
}
