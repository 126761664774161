.article-card {
  grid-column: span 12;
  * {
    text-decoration: none;
  }

  @include tablet {
    grid-column: span 6;
  }

  @include desktop {
    grid-column: span 4;
  }

  .article-card__content {
    padding: 16px 0;

    .article-card__content__title {
      display: block;
      color: $black;
      font-size: 24px;
      font-family: Georgia, sans-serif;
      margin-bottom: 8px;
      text-decoration: underline;
    }

    .article-card__content__ingress {
      display: block;
      color: $black;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }

  &:hover,
  :focus {
    .article-card__content__title {
      color: $green;
    }
    .article-card__content__ingress {
      color: $green;
    }
  }
}
