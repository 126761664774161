.header {
  background: $beige;

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 16px;

    .header-content__title {
      font-size: 16px;
      color: $green;
      font-size: 16px;
      font-family: Georgia, sans-serif;
      text-decoration: none;
      cursor: pointer;

      @include tablet {
        font-size: 24px;
      }

      @include desktop {
        font-size: 32px;
      }

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .header-content__menu {
    display: flex;
    align-items: center;
    column-gap: 16px;

    .header-content__menu__search {
      text-decoration: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      column-gap: 8px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
