.faq-container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.faq-box {
  background: $beige2;

  &:hover {
    background: $beige3;
  }

  .faq-box__summary {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 24px;

    .faq-box__summary__title {
      color: $black;
    }

    .faq-box__summary__icon {
      transition: 0.3s;
    }
  }

  .faq-box__content {
    padding: 0 48px 24px 48px;
  }
}

.faq-box[open] {
  background: white;
  transition: 0.3s;

  .faq-box__summary__icon {
    transform: rotate(90deg);
  }
}
