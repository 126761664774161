/* padding equal top bottom */

.padding--top-btm--8 {
  padding: 8px 0;
}

.padding--top-btm--16 {
  padding: 16px 0;
}

.padding--top-btm--24 {
  padding: 24px 0;
}

.padding--top-btm--32 {
  padding: 32px 0;
}

.padding--top-btm--40 {
  padding: 40px 0;
}

.padding--top-btm--48 {
  padding: 48px 0;
}

.padding--top-btm--56 {
  padding: 56px 0;
}

.padding--top-btm--64 {
  padding: 64px 0;
}

.padding--top-btm--72 {
  padding: 72px 0;
}

/* margins equal top bottom */

.margin--top-btm--8 {
  margin: 8px 0;
}

.margin--top-btm--16 {
  margin: 16px 0;
}

.margin--top-btm--24 {
  margin: 24px 0;
}

.margin--top-btm--32 {
  margin: 32px 0;
}

.margin--top-btm--40 {
  margin: 40px 0;
}

.margin--top-btm--48 {
  margin: 48px 0;
}

.margin--top-btm--56 {
  margin: 56px 0;
}

.margin--top-btm--64 {
  margin: 64px 0;
}

.margin--top-btm--72 {
  margin: 72px 0;
}

.margin--top-btm--80 {
  margin: 80px 0;
}

/* padding bottom only */

.padding--btm--8 {
  padding-bottom: 8px;
}

.padding--btm--16 {
  padding-bottom: 16px;
}

.padding--btm--24 {
  padding-bottom: 24px;
}

.padding--btm--32 {
  padding-bottom: 32px;
}

.padding--btm--40 {
  padding-bottom: 40px;
}

.padding--btm--48 {
  padding-bottom: 48px;
}

.padding--btm--56 {
  padding-bottom: 56px;
}

.padding--btm--64 {
  padding-bottom: 64px;
}

.padding--btm--72 {
  padding-bottom: 72px;
}

.padding--btm--80 {
  padding-bottom: 80px;
}

/* margins bottom */

.margin--btm--8 {
  margin-bottom: 8px;
}

.margin--btm--16 {
  margin-bottom: 16px;
}

.margin--btm--24 {
  margin-bottom: 24px;
}

.margin--btm--32 {
  margin-bottom: 32px;
}

.margin--btm--40 {
  margin-bottom: 40px;
}

.margin--btm--48 {
  margin-bottom: 48px;
}

.margin--btm--56 {
  margin-bottom: 56px;
}

.margin--btm--64 {
  margin-bottom: 64px;
}

.margin--btm--72 {
  margin-bottom: 72px;
}

.margin--btm--80 {
  margin-bottom: 80px;
}
