.footer {
  background: $green;
}

.footer__title {
  display: block;
  font-size: 24px;
  font-family: Georgia, sans-serif;
}

.footer-content {
  @extend .grid;
  row-gap: 40px;

  @include desktop {
    row-gap: 80px;
  }
}

.footer--start-page-name {
  grid-column: span 12;

  @include desktop {
    grid-column: span 6;
  }

  .footer--start-page-name__title {
    font-family: Georgia, sans-serif;
    font-size: 24px;
    color: white;

    @include tabletanddesktop {
      font-size: 32px;
    }
  }
}

.footer-links {
  grid-column: span 12;

  .footer-links__list {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  @include desktop {
    grid-column: span 3;
  }

  * {
    color: white;
    line-height: 100%;
  }
}

.footer-contact {
  grid-column: span 12;
  * {
    color: white;
  }

  @include desktop {
    grid-column: span 3;
  }

  .footer-contact__list {
    list-style: none;
    padding: 0;
    margin: 16px 0 0 0;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .footer-contact__list__el {
      color: white;
      font-size: 16px;
      line-height: 100%;
    }
  }
}

.footer-logo {
  grid-column: span 12;
  display: flex;

  @include desktop {
    flex-direction: row-reverse;
  }

  .footer-logo__image {
    width: 250px;
    vertical-align: middle;
  }
}
