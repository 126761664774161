.locationPage-body {
  grid-column: 1/13;

  @include tablet {
    grid-column: 2/12;
  }

  @include desktop {
    grid-column: 3/11;
  }
}
