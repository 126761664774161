.button--beige {
  background: $beige;
  border: none;
  border-radius: 40px;
  padding: 12px 40px;
  color: $green;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;

  &:focus,
  &:hover {
    background: $beige2;
    text-decoration: underline;
  }
}

.button--green {
  background: $green;
  border: none;
  border-radius: 40px;
  padding: 12px 40px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;

  &:focus,
  &:hover {
    background: $green2;
    text-decoration: underline;
  }
}

.button--medium--green
{
    min-height: 62px;
    width: 241px;
    text-align: center;
    display: inline-block;
    background: $green;
    border: none;
    border-radius: 40px;
    padding: 20px 24px;
    color: white;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none;

    &:focus,
    &:hover
    {
        background: $green2;
        text-decoration: underline;
    }
}

.button--small--green {
  display: inline-block;
  background: $green;
  border: none;
  border-radius: 40px;
  padding: 4px 16px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;

  &:focus,
  &:hover {
    background: $green2;
    text-decoration: underline;
  }
}
