.breadcrumbs {
  @extend .keep-in-main;
  height: 104px;
  display: flex;
  align-items: center;

  div {
    display: flex;
  }

  .breadcrumbs__link {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    column-gap: 8px;
    color: $black;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
