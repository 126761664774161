.search-container {
  background: #ebe7d6;

  .form-wrapper {
    display: flex;
    column-gap: 8px;

    .form-wrapper__input {
      flex: 1;
      padding: 8px 16px;
      border-radius: 4px;
      margin-top: 4px;
      border: none;
      font-size: 16px;
    }

    .form-wrapper__submit {
      background: #014325;
      color: white;
      padding: 0 16px;
      border-radius: 4px;
      margin-top: 4px;
      border: none;
      font-size: 16px;
      cursor: pointer;
    }

    .form-wrapper__submit:focus {
    outline: 3px solid green;
    outline-offset: 3px;
    }
  }
}

.search-result-ul {
  list-style: none;
  margin: 32px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 48px;

  .search-result-ul__li {
    padding: 0;
    margin: 0;

    .search-result-ul__li__title {
      display: block;
      font-size: 24px;
      color: #014325;
      margin-bottom: 8px;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    .search-result-ul__li__ingress {
      font-size: 18px;
    }
  }
}
