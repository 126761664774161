.graveAuthority-message {
  grid-column: 1/13;
  margin-top: 16px;

  @include desktop {
    grid-column: 3/11;
  }
}

.graveAuthority-ul {
  list-style: none;
  margin: 24px 0 0 0;
  padding: 0;

  .graveAuthority-ul__li {
    grid-column: 1/13;
    padding: 0;
    margin: 0;

    @include desktop {
      grid-column: 3/11;
    }

    .graveAuthority-ul__li__content {
      padding: 24px;
      background: #ebe7d6;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .graveAuthority-ul__li__content__title {
        font-family: Georgia, sans-serif;
        font-size: 24px;
        color: $green;
      }

      .graveAuthority-ul__li__content__select-wrapper {
        display: flex;
        align-items: center;
        column-gap: 8px;

        .graveAuthority-ul__li__content__select-wrapper__title {
          font-size: 16px;
          color: #333333;
          font-weight: 400;
        }
      }
    }

    &:hover {
      .graveAuthority-ul__li__content {
        background: $beige3;

        .graveAuthority-ul__li__content__title {
          text-decoration: underline;
        }
      }
    }
  }
}
