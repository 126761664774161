.ceremony-list {
  padding: 48px 0;

  .ceremony-list__headers {
    @extend .grid;
    background: $green;
    display: none;

    @include desktop {
      display: grid;
    }

    .ceremony-list__headers__element {
      grid-column: span 3;
      padding: 8px;
      color: $pink;
      font-family: Georgia, sans-serif;
    }
  }

  .ceremony-list__content {
    @extend .grid;
    row-gap: 0;
    border-top: 1px solid $grey;
    &:last-of-type {
      border-bottom: 1px solid $grey;
    }

    .ceremony-list__content__element {
      grid-column: span 12;
      padding: 8px 0;

      @include tablet {
        grid-column: span 6;
        padding: 8px 0;
      }

      @include desktop {
        grid-column: span 3;
        padding: 8px;
      }

      .ceremony-list__content__element__title {
        display: block;
        font-family: Georgia, sans-serif;
        font-weight: 600;
        margin-bottom: 4px;

        @include tablet {
          display: block;
        }

        @include desktop {
          display: none;
        }
      }
    }
  }
}
