@import "./colors";

.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 16px;
  row-gap: 16px;
}

.grid--no-gap {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
